import { RequestActions, RequestConstants } from '../../request';
import {
  FETCH_ROLES_KEY,
  organizationRolesUrl,
} from '../constants';

export const fetchOrganizationRoles = organizationId => RequestActions.request({
  url: organizationRolesUrl(organizationId),
  key: FETCH_ROLES_KEY,
  method: RequestConstants.GET,
  errorMessage: 'roles.errors.loadRolesFailed',
});
