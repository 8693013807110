import _ from 'lodash';
import { requestStatuses } from '../../common/requestStatuses';

export const getRole = state => _.get(state, 'roles.role');
export const getRoleSaving = state => _.get(state, 'roles.saving');

export const roleByTypeAndIdSelector = (state, type, id) => (
  state.roles.entities[type].find(role => role.id === id)
);

export const rolesSelector = (state) => {
  const entities = _.get(state, 'roles.entities');

  const sortedCustomRoles = _.chain(entities.customRoles)
    .sortBy('name')
    .value();

  return {
    systemRoles: entities.systemRoles,
    customRoles: sortedCustomRoles,
  };
};

export const rolesLoadedSelector = state => _.get(state, 'roles.status') === requestStatuses.LOADED;
