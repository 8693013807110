import { get } from 'lodash';
import { RequestActions, RequestConstants } from '../../request';
import {
  organizationUsersUrl,
  USERS_FETCH_KEY,
  ARCHIVE_USER,
  RESTORE_USER,
  RESEND_INVITE_USER,
  REMOVE_PERMITTED_ORGANIZATION_USER,
  FAILED_RESPONSE,
  NOT_FOUND,
} from '../constants';
import * as FlashActionCreators from '../../flash/actionCreators';

const fetchOrganizationUsers = organizationId => RequestActions.request({
  key: USERS_FETCH_KEY,
  method: RequestConstants.GET,
  url: organizationUsersUrl(organizationId),
  errorMessage: 'userAdmin.list.errors.loadUsersFailed',
});

const archiveUser = user => async (dispatch) => {
  const response = await dispatch(RequestActions.request({
    key: ARCHIVE_USER,
    method: RequestConstants.PUT,
    url: user.links.archive,
    successMessage: 'userAdmin.list.archiveSuccess',
    content: { userId: user.id },
  }));

  if (response.error) {
    if (response.error.status === NOT_FOUND) {
      dispatch(FlashActionCreators.alert('userAdmin.list.notFound'));
    } else if (response.error.status === FAILED_RESPONSE) {
      dispatch(FlashActionCreators.alert('userAdmin.list.archiveFailure'));
    }
  }
};

const restoreUser = user => async (dispatch) => {
  const response = await dispatch(RequestActions.request({
    key: RESTORE_USER,
    method: RequestConstants.PUT,
    url: user.links.restore,
    successMessage: 'userAdmin.list.restoreSuccess',
    content: { userId: user.id },
  }));

  if (response.error) {
    if (response.error.status === NOT_FOUND) {
      dispatch(FlashActionCreators.alert('userAdmin.list.notFound'));
    } else if (response.error.status === FAILED_RESPONSE) {
      dispatch(FlashActionCreators.alert('userAdmin.list.restoreFailure'));
    }
  }
};

const resendInvite = user => async (dispatch) => {
  const response = await dispatch(RequestActions.request({
    key: RESEND_INVITE_USER,
    method: RequestConstants.POST,
    url: user.links.invite,
    content: { userId: user.id },
  }));

  if (response.success) {
    const inviteLink = get(response, 'success.body.links.invite', false);
    const successMessage = inviteLink ? 'userAdmin.list.resendSuccess' : 'userAdmin.list.inviteAccepted';
    dispatch(FlashActionCreators.notice(successMessage));
  }

  if (response.error) {
    if (response.error.status === NOT_FOUND) {
      dispatch(FlashActionCreators.alert('userAdmin.list.notFound'));
    } else if (response.error.status === FAILED_RESPONSE) {
      dispatch(FlashActionCreators.alert('userAdmin.list.resendFailure'));
    }
  }
};

const removeUser = (user, organizationId) => async (dispatch) => {
  const response = await dispatch(RequestActions.request({
    key: REMOVE_PERMITTED_ORGANIZATION_USER,
    method: RequestConstants.PUT,
    url: user.links.remove_permitted_organization,
    successMessage: 'userAdmin.list.removeSuccess',
    content: { ...user, userId: user.id, organizationId },
  }));

  if (response.error) {
    if (response.error.status === NOT_FOUND) {
      dispatch(FlashActionCreators.alert('userAdmin.list.notFound'));
    } else if (response.error.status === FAILED_RESPONSE) {
      dispatch(FlashActionCreators.alert('userAdmin.list.removeFailure'));
    }
  }
};

export const UsersActions = {
  fetchOrganizationUsers,
  archiveUser,
  restoreUser,
  resendInvite,
  removeUser,
};
