import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { filter, every } from 'lodash';

import Toggle from '../../common/forms/Toggle';

const ToggleClosedDay = ({
  timeslots, onTimeslotsUpdate, dayOfWeekNum,
}) => {
  const { formatMessage } = useIntl();

  const timeslotsForDay = filter(timeslots, { dayOfWeekNum });
  const isClosedDay = every(timeslotsForDay, 'isClosed');

  const handleClosedDayToggle = () => {
    onTimeslotsUpdate(timeslots.map((timeslot) => {
      if (timeslot.dayOfWeekNum === dayOfWeekNum) {
        return { ...timeslot, isClosed: !isClosedDay };
      }
      return timeslot;
    }));
  };

  return (
    <div className='business-hours__toggle'>
      <Toggle
        checked={ !isClosedDay }
        dataTest='closed.day.toggle'
        onChange={ handleClosedDayToggle }
        primaryLabel={ formatMessage({ id: 'timeslots.editor.toggleClosedDay' }) }
      />
    </div>
  );
};

ToggleClosedDay.propTypes = {
  timeslots: PropTypes.arrayOf(PropTypes.shape({
    dayOfWeekNum: PropTypes.number,
    isClosed: PropTypes.bool,
  })).isRequired,
  onTimeslotsUpdate: PropTypes.func.isRequired,
  dayOfWeekNum: PropTypes.number,
};

ToggleClosedDay.defaultProps = {
  dayOfWeekNum: null,
};

export default ToggleClosedDay;
