import _ from 'lodash';
import { createSelector } from 'reselect';
import { UserRole } from '../../application/UserRole';
import {
  locationsAndGroupsSelector,
  getLocationIdsFromTimelineTemplateIds,
  locationsWithArchivedSeparatedSelector,
} from '../../sites/selectors/locationSelectors';
import { ALL_SITES, DEMO_STATUS } from '../constants';

const isNotAdmin = ({ role }) => !(new UserRole(role)).isOrgAdminOrAbove();

export const organizationUsersSelector = state => state.users.entities;
export const organizationUsersLoadedSelector = state => state.users.loaded;

export const nonAdminUsersSelector = createSelector(
  organizationUsersSelector,
  users => _.chain(users).values().filter(isNotAdmin).value());

const getUsersWithLocationIds = (users, locationsAndGroups) => users.map(user => ({
  ...user,
  locationIds: getLocationIdsFromTimelineTemplateIds(
    locationsAndGroups,
    user.timelineTemplateIds
  ),
}));

const addUserToLocationGroup = (usersByLocation, locationId, user) => {
  if (!usersByLocation[locationId]) {
    usersByLocation[locationId] = [];
  }
  usersByLocation[locationId].push(user);
};

export const usersListSelector = state => Object.values(state.users.entities);

const usersWithLocationIdsSelector = createSelector(
  [usersListSelector, locationsAndGroupsSelector],
  (users, locationsAndGroups) => users.map(user => ({
    ...user,
    locationIds: getLocationIdsFromTimelineTemplateIds(
      locationsAndGroups,
      user.timelineTemplateIds
    ),
  }))
);

export const usersByLocationForUserListSelector = createSelector(
  [
    usersListSelector,
    locationsAndGroupsSelector,
  ],
  (users, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    const addUserToAllLocations = (usersByLocation, user) => {
      Object.values(locationsAndGroups.locations).forEach((location) => {
        addUserToLocationGroup(usersByLocation, location.id, user);
      });
    };

    const addUserToSpecificLocations = (usersByLocation, user) => {
      user.locationIds.forEach((locationId) => {
        addUserToLocationGroup(usersByLocation, locationId, user);
      });
    };

    return usersWithLocationIds.reduce((usersByLocation, user) => {
      if (user.locations === ALL_SITES) {
        addUserToAllLocations(usersByLocation, user);
      } else {
        addUserToSpecificLocations(usersByLocation, user);
      }
      return usersByLocation;
    }, {});
  }
);

const sortAlphabeticallyArchivedLast = users => (
  [...(users || [])].sort((a, b) => {
    if (a.archived && !b.archived) return 1;
    if (b.archived && !a.archived) return -1;
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  })
);

export const filteredUsersByLocationSelectorWithArchivedLast = createSelector(
  [
    (state, selectedLocationIds) => selectedLocationIds,
    usersListSelector,
    locationsAndGroupsSelector,
  ],
  (selectedLocationIds, users, locationsAndGroups) => {
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    const filteredUsers = usersWithLocationIds.filter((user) => {
      if (user.locations === ALL_SITES) return true;
      if (!selectedLocationIds.length) return true;
      return user.locationIds.some(locationId => selectedLocationIds.includes(locationId));
    });
    return sortAlphabeticallyArchivedLast(filteredUsers);
  }
);

const sortLocationsWithDemoFirst = (a, b) => (
  b.status === DEMO_STATUS) - (a.status === DEMO_STATUS);

export const userListLocationsSelector = createSelector(
  [
    (state, selectedLocationIds) => selectedLocationIds,
    locationsWithArchivedSeparatedSelector,
    usersByLocationForUserListSelector,
  ],

  (
    selectedLocationIds,
    { notArchivedLocations: locations },
    usersByLocation
  ) => locations.map(location => ({
    id: location.id,
    label: location.name,
    checked: selectedLocationIds.includes(location.id),
    status: location.status,
    secondaryLabel: _.get(usersByLocation, `${location.id}.length`, 0),
  })).sort(sortLocationsWithDemoFirst)
);

export const userListGroupSelector = createSelector(
  [locationsAndGroupsSelector, usersListSelector, (state, selectedGroupIds) => selectedGroupIds],
  (locationsAndGroups, users, selectedGroupIds) => {
    const groups = Object.values(locationsAndGroups.groups || {});
    const usersWithLocationIds = getUsersWithLocationIds(users, locationsAndGroups);

    return groups.map((group) => {
      const groupLocations = new Set(group.locations || []);
      let groupUsers = [];

      if (groupLocations.size > 0) {
        groupUsers = usersWithLocationIds.filter(
          user => user.locationIds.some(locationId => groupLocations.has(locationId)) ||
            user.locations === ALL_SITES
        );
      } else {
        groupUsers = usersWithLocationIds.filter(
          user => user.timelineTemplateIds && user.timelineTemplateIds.includes(group.id)
        );
      }

      if (groupLocations.size === 0 && groupUsers.length === 0) {
        groupUsers = [];
      }

      const userCount = groupUsers.length;

      return {
        id: group.id,
        label: group.name,
        checked: selectedGroupIds.includes(group.id),
        secondaryLabel: userCount > 0 ? userCount : '0',
      };
    });
  }
);

const combinedSelectedLocationIdsSelector = createSelector(
  [
    (state, props) => props.selectedGroupIds || [],
    (state, props) => props.selectedLocationIds || [],
    locationsAndGroupsSelector,
  ],
  (selectedGroupIds, selectedLocationIds, locationsAndGroups) => {
    const selectedLocationsByGroup = _.flatMap(
      Object.values(locationsAndGroups.groups || {}).filter(
        group => selectedGroupIds.includes(group.id)
      ),
      'locations'
    );

    return _.uniq([...selectedLocationIds, ...selectedLocationsByGroup]);
  }
);

export const filteredUsersSelector = createSelector(
  [
    (state, props) => props.selectedGroupIds,
    (state, props) => props.selectedLocationIds,
    state => state,
    usersWithLocationIdsSelector,
  ],
  (selectedGroupIds, selectedLocationIds, state, usersWithLocationIds) => {
    const combinedSelectedLocationIds = combinedSelectedLocationIdsSelector(
      state, { selectedGroupIds, selectedLocationIds });

    if (selectedGroupIds.length === 0 && selectedLocationIds.length === 0) {
      return sortAlphabeticallyArchivedLast(usersWithLocationIds);
    }

    if (selectedGroupIds.length > 0 && combinedSelectedLocationIds.length === 0) {
      const filteredUsers = usersWithLocationIds.filter(
        user => selectedGroupIds.some(
          groupId => user.timelineTemplateIds && user.timelineTemplateIds.includes(groupId))
      );
      return sortAlphabeticallyArchivedLast(filteredUsers);
    }

    const filteredUsers = usersWithLocationIds.filter((user) => {
      if (user.locations === ALL_SITES) return true;
      if (!combinedSelectedLocationIds.length) return true;
      return user.locationIds.some(
        locationId => combinedSelectedLocationIds.includes(locationId)) ||
        selectedGroupIds.some(
          groupId => user.timelineTemplateIds && user.timelineTemplateIds.includes(groupId));
    });

    return sortAlphabeticallyArchivedLast(filteredUsers);
  }
);
