import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';
import OfflineInteractionBlocker from '../offline-interaction-blocker';

const LEFT = 'left';
export const RIGHT = 'right';

class Button extends PureComponent {
  _getButtonLabel() {
    let label = null;

    if (this.props.label || this.props.children) {
      label = (
        <span key='label' className='showtime-button__text'>
          {this.props.label || this.props.children}
        </span>
      );
    }

    return label;
  }

  _getButtonSecondaryLabel() {
    let secondaryLabel = null;

    if (this.props.secondaryLabel) {
      secondaryLabel = (
        <span key='secondaryLabel' className='showtime-button__secondary-text'>
          {this.props.secondaryLabel}
        </span>
      );
    }

    return secondaryLabel;
  }

  _getIconForPosition(position) {
    const shouldRenderIcon = this.props.iconName && this.props.iconPosition === position;
    return shouldRenderIcon && (
      <SVGIcon
        key={ `icon${position}${this.props.iconName}` }
        classes={ this.props.buttonIconClass }
        iconName={ this.props.iconName }
      />
    );
  }

  _getCountForPosition(position) {
    let count = null;

    if (this.props.counts && this.props.counts[position]) {
      count = (
        <span
          key={ `count${position}${this.props.counts[position]}` }
          className='showtime-button__count'
        >
          <span className='showtime-button__count-text'>
            {this.props.counts[position]}
          </span>
        </span>
      );
    }

    return count;
  }

  render() {
    const buttonClasses = classNames(
      `${this.props.baseClass} ${this.props.buttonClasses} ${this.props.className}`,
      {
        'showtime-button--small': this.props.small,
        'showtime-button--link': this.props.link,
        'showtime-button--no-padding': this.props.noPadding,
        'showtime-button--no-shadow': this.props.noShadow,
        'showtime-button--margin-top': this.props.marginTop,
        'showtime-button--margin-bottom': this.props.marginBottom,
        'showtime-button--block': this.props.block,
        'showtime-button--highlight': this.props.highlight,
        'showtime-button--lowlight': this.props.lowlight,
        'showtime-button--collapse-icon': this.props.collapseIcon,
        'showtime-button--collapse-text': this.props.collapseText,
        'showtime-button--highlight-alt': this.props.highlightAlt,
        'showtime-button--lowlight-alt': this.props.lowlightAlt,
        'is-disabled-highlight': this.props.disabledHighlight,
        'is-disabled--no-pointer-events': this.props.disabled,
        'is-loading': this.props.loading,
        'showtime-button--default': this.props.default,
        'showtime-button--adjacent': this.props.adjacent,
        'showtime-button--create': this.props.create,
      }
    );

    const innerElements = [
      this._getCountForPosition(LEFT),
      this._getIconForPosition(LEFT),
      this._getButtonLabel(),
      this._getButtonSecondaryLabel(),
      this._getIconForPosition(RIGHT),
      this._getCountForPosition(RIGHT),
    ];

    let element = null;
    const { dataTest, dataHint, dataAttributes } = this.props;
    const dataAttributeProps = {
      ..._.mapKeys(dataAttributes, (v, k) => `data-${k}`),
      ...dataTest && { 'data-test': dataTest },
      ...dataHint && { 'data-hint': dataHint },
    };

    if (this.props.onClick) {
      element = (
        <a
          { ...dataAttributeProps }
          ref='button'
          onClick={ this.props.onClick }
          onMouseLeave={ this.props.onMouseLeave }
          href='javascript:void(0)'
          className={ buttonClasses }
        >
          {innerElements}
        </a>
      );
    } else if (this.props.method === 'post') {
      const formName = _.uniqueId('form');
      element = [
        <form
          { ...dataAttributeProps }
          hidden
          key='1'
          name={ formName }
          action={ this.props.uri }
          method='post'
        />,
        <a
          { ...dataAttributeProps }
          key='2'
          ref='button'
          onClick={ () => document.forms[formName].submit() }
          onMouseLeave={ this.props.onMouseLeave }
          className={ buttonClasses }
        >
          {innerElements}
        </a>,
      ];
    } else {
      element = (
        <a
          { ...dataAttributeProps }
          ref='button'
          href={ this.props.uri }
          className={ buttonClasses }
        >
          {innerElements}
        </a>
      );
    }

    if (this.props.blockOffline && !this.props.intl) return null;

    if (this.props.blockOffline) {
      return (
        <OfflineInteractionBlocker
          tooltipMessage={ this.props.intl.formatMessage({ id: 'offline.notAvailable' }) }
          fullWidth={ this.props.block }
        >
          { element }
        </OfflineInteractionBlocker>
      );
    }

    return element;
  }
}

Button.propTypes = {
  uri: PropTypes.string,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  baseClass: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonIconClass: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  secondaryLabel: PropTypes.string,
  method: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf([RIGHT, LEFT]),
  counts: PropTypes.object,
  dataTest: PropTypes.string,
  dataHint: PropTypes.string,
  dataAttributes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  small: PropTypes.bool,
  link: PropTypes.bool,
  noShadow: PropTypes.bool,
  noPadding: PropTypes.bool,
  marginTop: PropTypes.bool,
  highlight: PropTypes.bool,
  lowlight: PropTypes.bool,
  highlightAlt: PropTypes.bool,
  block: PropTypes.bool,
  lowlightAlt: PropTypes.bool,
  marginBottom: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledHighlight: PropTypes.bool,
  loading: PropTypes.bool,
  blockOffline: PropTypes.bool,
  collapseIcon: PropTypes.bool,
  default: PropTypes.bool,
  adjacent: PropTypes.bool,
  create: PropTypes.bool,
  collapseText: PropTypes.bool,
};

Button.defaultProps = {
  uri: '#',
  method: 'get',
  className: '',
  baseClass: 'showtime-button',
  buttonClasses: '',
  buttonIconClass: 'showtime-button__icon',
  onClick: undefined,
  onMouseLeave: () => {},
  label: '',
  secondaryLabel: '',
  dataTest: undefined,
  dataHint: undefined,
  iconName: undefined,
  iconPosition: LEFT,
  dataAttributes: undefined,
  disabledHighlight: undefined,
  counts: undefined,
  children: null,
  small: undefined,
  link: undefined,
  noShadow: undefined,
  noPadding: undefined,
  marginTop: undefined,
  highlight: undefined,
  lowlight: undefined,
  highlightAlt: undefined,
  marginBottom: undefined,
  block: undefined,
  lowlightAlt: undefined,
  disabled: undefined,
  loading: undefined,
  blockOffline: undefined,
  collapseIcon: undefined,
  default: undefined,
  adjacent: undefined,
  create: undefined,
  collapseText: undefined,
};

export default Button;
