import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, find } from 'lodash';

import TimeslotBlocks from './TimeslotBlocks';
import TimeslotForm from './TimeslotForm';
import ClosedTimeslots from './ClosedTimeslots';
import BusinessDayHours from './BusinessDayHours';
import { mapTimeslotsDuration } from './utils';
import LoadingSpinner from '../../common/LoadingSpinner';
import ToggleClosedDay from './ToggleClosedDay';
import TimeslotMarkers from './TimeslotMarkers';

const TimeslotsEditor = ({
  timeslots,
  dayOfWeekNum,
  loading,
  onTimeslotsUpdate,
  dayHasOverride,
  defaultTimeslots,
  viewingCustomMessage,
  viewingDefaultMessage,
  viewDefaultHoursButton,
}) => {
  const [focusedTimeslot, setFocusedTimeslot] = useState(null);

  if (loading) return <LoadingSpinner />;

  const visibleTimeslots = filter(mapTimeslotsDuration(timeslots), { dayOfWeekNum });
  const hasOpenTimeslots = visibleTimeslots.some(timeslot => !timeslot.isClosed);

  return (
    <div className='business-hours'>
      <ToggleClosedDay { ...{
        timeslots, onTimeslotsUpdate, dayOfWeekNum,
      } }
      />
      { hasOpenTimeslots && ([
        <div className='business-hours__row' key='timeslots'>
          <div className='business-hours__preview' data-test='timeslots.editor'>
            <BusinessDayHours timeslots={ visibleTimeslots } />
            <TimeslotMarkers timeslots={ visibleTimeslots } />
            <TimeslotBlocks
              timeslots={ visibleTimeslots }
              onTimeslotClick={ setFocusedTimeslot }
              focusedTimeslot={ focusedTimeslot }
            />
          </div>
          <TimeslotForm
            timeslots={ timeslots }
            focusedTimeslot={ find(timeslots, { dayOfWeekNum, baseTimeslotUuid: focusedTimeslot }) }
            onTimeslotsUpdate={ onTimeslotsUpdate }
            dayHasOverride={ dayHasOverride }
            onTimeslotClose={ (updatedTimeslots) => {
              onTimeslotsUpdate(updatedTimeslots);
              setFocusedTimeslot(null);
            } }
            defaultTimeslots={ defaultTimeslots }
            dayOfWeekNum={ dayOfWeekNum }
            viewingCustomMessage={ viewingCustomMessage }
            viewingDefaultMessage={ viewingDefaultMessage }
            viewDefaultHoursButton={ viewDefaultHoursButton }
          />
        </div>,

        <ClosedTimeslots
          { ...{ visibleTimeslots, timeslots, onTimeslotsUpdate } }
          key='closed-timeslots'
        />,
      ])}
    </div>
  );
};

const timeslotProps = PropTypes.shape({
  duration: PropTypes.number,
  name: PropTypes.string,
  hour: PropTypes.number,
  minute: PropTypes.number,
  isClosed: PropTypes.bool,
  dayOffset: PropTypes.number,
  baseTimeslotUuid: PropTypes.string,
});

TimeslotsEditor.propTypes = {
  timeslots: PropTypes.arrayOf(timeslotProps).isRequired,
  loading: PropTypes.bool,
  onTimeslotsUpdate: PropTypes.func,
  dayOfWeekNum: PropTypes.number,
  dayHasOverride: PropTypes.bool,
  viewDefaultHoursButton: PropTypes.node,
  viewingCustomMessage: PropTypes.string,
  viewingDefaultMessage: PropTypes.string,
  defaultTimeslots: PropTypes.arrayOf(timeslotProps).isRequired,
};

TimeslotsEditor.defaultProps = {
  loading: false,
  onTimeslotsUpdate() {},
  dayOfWeekNum: null,
  dayHasOverride: false,
  viewDefaultHoursButton: null,
  viewingCustomMessage: '',
  viewingDefaultMessage: '',
};

export default TimeslotsEditor;
