import { onlyShowFeaturesThatCustomerSees } from './shadowingFeaturesToggler';

const UserRole = function UserRole(role, userIsBeingShadowed, customRolePermissions) {
  this.userRole = role;
  this.userIsBeingShadowed = userIsBeingShadowed;
  this.customRolePermissions = customRolePermissions || {};
};

UserRole.prototype.roles = {
  END_USER: 'enduser',
  MANAGER: 'manager',
  ORG_ADMIN: 'organization_admin',
  ADMIN: 'admin',
};

UserRole.permissionTypes = {
  COMPLETE_RESTRICTED_WIDGET_FIELDS: 'complete_restricted_widget_fields',
  REOPEN_LOCKED_TIMELINE_TASKS: 'reopen_locked_timeline_tasks',
  MANAGE_LOCATION_SUBTASKS: 'manage_location_subtasks',
  MANAGE_RESTRICTED_WIDGET_ROWS: 'manage_restricted_widget_rows',
  FLAG_TASK_NON_RECURRING: 'flag_task_non_recurring',
  FLAG_TASK_RECURRING: 'flag_task_recurring',
  ACCESS_TASK_REPORTS: 'access_task_reports',
  ACCESS_SCORES: 'access_scores',
  ACCESS_ACTIVITY: 'access_activity',
  DOWNLOAD_TASK_REPORTS: 'download_task_reports',
  DOWNLOAD_SCORES: 'download_scores',
  MANAGE_ENDUSERS: 'manage_endusers',
  MANAGE_USERS_WITH_OWN_ROLE: 'manage_users_with_own_role',
  MANAGE_LOCATION_TIMESLOTS: 'manage_location_timeslots',
  MANAGE_LOCATION_STATUS: 'manage_location_status',
  MANAGE_PERMITTED_TASKS: 'manage_permitted_tasks',
  VIEW_OTHER_CUSTOM_ROLE_TASKS: 'view_other_custom_role_tasks',
};

UserRole.prototype.isEnduser = function () {
  return this.userRole === this.roles.END_USER;
};

UserRole.prototype.isManager = function () {
  return this.userRole === this.roles.MANAGER;
};

UserRole.prototype.isManagerOrAbove = function () {
  return this.userRole !== this.roles.END_USER;
};

UserRole.prototype.isOrgAdmin = function () {
  return this.userRole === this.roles.ORG_ADMIN;
};

UserRole.prototype.isOrgAdminOrAbove = function () {
  return this.userRole === this.roles.ORG_ADMIN ||
         this.userRole === this.roles.ADMIN;
};

UserRole.prototype.isSuperAdmin = function () {
  return this.userRole === this.roles.ADMIN;
};

UserRole.prototype.isSuperAdminOrBeingShadowed = function () {
  return this.isSuperAdmin() || this.userIsBeingShadowed;
};

UserRole.prototype.isBeingShadowedWithAllFeatures = function () {
  return this.userIsBeingShadowed
    ? !onlyShowFeaturesThatCustomerSees()
    : false;
};

UserRole.prototype.isSuperAdminOrBeingShadowedWithAllFeatures = function () {
  if (this.isSuperAdmin()) return true;

  return this.isBeingShadowedWithAllFeatures();
};

UserRole.prototype.isPermissionEnabled = function (permission) {
  return this.customRolePermissions[permission] === true;
};

UserRole.prototype.isPermissionDisabled = function (permission) {
  return this.customRolePermissions[permission] === false;
};

UserRole.prototype.hasCustomRole = function () {
  return Object.keys(this.customRolePermissions).length !== 0;
};

const getCurrentUserRole = () => new UserRole(
  window.current_user.role,
  window.current_user.being_shadowed,
  window.current_user.custom_role_permissions
);

export { UserRole, getCurrentUserRole };
