export const SET_TASK_COMPLETION_USER_SUBSCRIPTION = 'report.set_task_completion_user_subscription';

export const CLEAR_ALL_TASK_COMPLETION_SUBSCRIPTIONS = 'report.clear_all_task_completion_subscriptions';

export const selectMode = {
  OFF: 'off',
  COMPLETED_WITH_EXCEPTIONS: 'task_completed_with_exceptions',
  COMPLETED: 'task_completed',
};

export const SET_SELECT_MODE = 'report.set_select_mode';
export const SET_NOTIFY_ALL_USERS = 'report.set_notify_all_users';
export const EMAIL_NOTIFICATION_URL = 'http://answers.trailapp.com/en/articles/2318707-who-receives-email-notifications';
export const EMAIL_NOTIFICATION_METRIC_NAME = 'Email notifications';
