import { RequestConstants } from '../../request';
import { requestStatuses } from '../../common/requestStatuses';
import {
  CREATE_CUSTOM_ROLE_KEY,
  UPDATE_CUSTOM_ROLE_KEY,
  CLEAR_ROLE_PANEL,
  SET_EDITING_ROLE,
  SET_ROLE_NAME,
  SET_ROLE_DESCRIPTION,
  TOGGLE_ROLE_PERMISSION,
  FETCH_ROLES_KEY,
} from '../constants';
import { UserRole } from '../../application/UserRole';

const initialState = {
  saving: false,
  role: {
    name: '',
    description: '',
    ...Object.values(UserRole.permissionTypes).reduce(
      (acc, permission) => ({ ...acc, [permission]: false }),
      {}
    ),
  },
  entities: {
    customRoles: [],
    systemRoles: [],
  },
  status: requestStatuses.NOT_REQUESTED,
};

export const rolesReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case RequestConstants.getLoadedActionType(FETCH_ROLES_KEY):
      return {
        ...state,
        status: requestStatuses.LOADED,
        entities: action.content,
      };
    case RequestConstants.getLoadingActionType(FETCH_ROLES_KEY):
      return { ...state, status: requestStatuses.LOADING };
    case RequestConstants.getErrorActionType(FETCH_ROLES_KEY):
      return { ...state, status: requestStatuses.ERROR };
    case RequestConstants.getLoadingActionType(CREATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: true };
    case RequestConstants.getLoadedActionType(CREATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: false };
    case RequestConstants.getErrorActionType(CREATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: false };
    case RequestConstants.getLoadingActionType(UPDATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: true };
    case RequestConstants.getLoadedActionType(UPDATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: false };
    case RequestConstants.getErrorActionType(UPDATE_CUSTOM_ROLE_KEY):
      return { ...state, saving: false };
    case CLEAR_ROLE_PANEL:
      return { ...initialState, entities: state.entities, status: state.status };
    case SET_EDITING_ROLE:
      return {
        ...state,
        role: {
          ...state.role,
          name: action.role.name,
          description: action.role.description,
          ...action.role.permissions,
        },
      };
    case SET_ROLE_NAME:
      return { ...state, role: { ...state.role, name: action.name } };
    case SET_ROLE_DESCRIPTION:
      return { ...state, role: { ...state.role, description: action.description } };
    case TOGGLE_ROLE_PERMISSION:
      return {
        ...state,
        role: { ...state.role, [action.permissionName]: !state.role[action.permissionName] },
      };
    default:
      return state;
  }
};
