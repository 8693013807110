import { get, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import { isLoaded } from '../../common/requestStatuses';
import { initialOrgTimeslotsSelector } from './organizationTimeslotsSelectors';
import { areTimeslotAttributesEqual } from '../utils';

const timeslotsSelector = state => get(state, 'timeslots', {});
const locationIdSelector = (state, { locationId }) => locationId;

export const locationTimeslotsSelector = createSelector(
  timeslotsSelector,
  locationIdSelector,
  (timeslots, locationId) => {
    if (timeslots.location.id !== locationId) return [];

    return timeslots.location.entities;
  }
);

export const closedLocationWeekdaysSelector = createSelector(
  locationTimeslotsSelector,
  (locationTimeslots) => {
    const locationTimeslotsByDay = groupBy(locationTimeslots, 'dayOfWeekNum');
    return Object.keys(locationTimeslotsByDay).reduce((accu, dayOfWeekNum) => {
      const dayTimeslots = locationTimeslotsByDay[dayOfWeekNum];
      const dayClosed = dayTimeslots.every(timeslot => timeslot.isClosed);
      if (dayClosed) return [...accu, Number(dayOfWeekNum)];
      return accu;
    }, []);
  }
);

export const daysWithOverridesSelector = createSelector(
  locationTimeslotsSelector,
  initialOrgTimeslotsSelector,
  (locationTimeslots, orgTimeslots) => {
    const dayOfWeekNums = locationTimeslots.reduce((accu, timeslot, index) => {
      if (areTimeslotAttributesEqual(timeslot, orgTimeslots[index])) return accu;
      if (accu.includes(timeslot.dayOfWeekNum)) return accu;
      return [...accu, timeslot.dayOfWeekNum];
    }, []);

    return dayOfWeekNums;
  }
);

export const locationTimeslotsLoadedSelector = createSelector(
  timeslotsSelector,
  locationIdSelector,
  (timeslots, locationId) => {
    if (timeslots.location.id !== locationId) return false;

    return isLoaded(timeslots.location.requestStatus);
  }
);
