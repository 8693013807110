import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import moment from 'moment-timezone';
import { getHourWithDayOffset } from './utils';
import { HOUR_MINUTES, DAY_HOURS } from './constants';

const BusinessDayHours = ({ timeslots }) => {
  const firstHour = getHourWithDayOffset({ ...timeslots[0] });
  const lastTimeslot = timeslots[timeslots.length - 1];
  const lastHour = getHourWithDayOffset({ ...lastTimeslot })
    + (lastTimeslot.duration / HOUR_MINUTES) + 1;

  return (
    <div className='business-hours__timeline'>
      {range(firstHour, lastHour).map(hour => (
        <div key={ hour } className='business-hours__hour'>
          <div className='business-hours__hour-label' data-test='timeline.hour'>
            { moment({ hour: hour % DAY_HOURS }).format('h a') }
          </div>
          <div className='business-hours__hour-half' />
        </div>
      ))}
    </div>
  );
};

BusinessDayHours.propTypes = {
  timeslots: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number,
    name: PropTypes.string,
    hour: PropTypes.number,
    minute: PropTypes.number,
    isClosed: PropTypes.bool,
    dayOffset: PropTypes.number,
    baseTimeslotUuid: PropTypes.string,
  })).isRequired,
};

export default BusinessDayHours;
