import moment from 'moment-timezone';
import EventEmitter from 'wolfy87-eventemitter';
import _, { isEqual, get } from 'lodash';
import AppDispatcher from '../../application/dispatcher';
import { TrailStore } from '.';
import { TrailConstants, WidgetDataConstants } from '../constants';
import { WidgetModelRegistry } from '../../widget/widgetModelRegistry';
import { DataCaptureScoreSelectors } from '../../widget/selectors';
import { DATA_TYPE_REGEX } from '../../widget/constants';
import calculateVisibleFieldsByLogic from '../../widget/calculateVisibleFieldsByLogic';

const preserveNaNInUpdatedRow = (originalRow, updatedRow) => _.mapValues(
  updatedRow, (newValue, fieldName) => {
    const newValueIsEmpty = newValue === null;
    const originalValue = originalRow[fieldName];
    if (Number.isNaN(originalValue) && newValueIsEmpty) return NaN;
    return newValue;
  });

const isDataCaptureWidget = widget => DATA_TYPE_REGEX.dataCapture.test(widget.type);

/* eslint-disable new-cap */
const WidgetDataStore = new _.extend({}, EventEmitter.prototype, {

  _widget_data: {},

  getWidget(id) {
    return this._widget_data[id] || {};
  },

  getAllWidgetData() {
    return this._widget_data;
  },

  isSubmitted(id) {
    return Boolean(this.getWidget(id) && this.getWidget(id).submitted_at);
  },

  setWidgetData(newWidgetData) {
    const updatedWidgetIds = [];
    (newWidgetData || []).forEach((widgetData) => {
      const WidgetModelClass = WidgetModelRegistry.getWidgetModelForType(widgetData.type);
      widgetData = WidgetModelClass ? new WidgetModelClass(widgetData) : widgetData;

      const canMerge = !!this._widget_data[widgetData.id] && isDataCaptureWidget(widgetData);

      const wasUpdated = canMerge
        ? this.mergeDataCaptureRows(widgetData.id, widgetData.custom_fields.dataCaptureRows)
        : this.crudeReplaceWidget(widgetData);

      if (!wasUpdated) return;

      this.emitUpdateForWidget(widgetData.id);
      updatedWidgetIds.push(widgetData.id);
    });

    this.emit(WidgetDataConstants.STORE_SET, updatedWidgetIds);
  },

  updateWidgetByPusher(id, { dataCaptureRows = [] }) {
    if (!(id in this._widget_data)) return;

    this.mergeDataCaptureRows(id, dataCaptureRows);

    this.emit(WidgetDataConstants.WIDGET_UPDATED_BY_PUSHER, id);
    this.emitUpdateForWidget(id);
  },

  crudeReplaceWidget(newWidgetData) {
    if (isEqual(this.getWidget(newWidgetData.id), newWidgetData)) return false;

    this._widget_data[newWidgetData.id] = newWidgetData;
    return true;
  },

  mergeDataCaptureRows(id, dataCaptureRows = []) {
    const originalCustomFields = get(this._widget_data, [id, 'custom_fields'], {});
    const originalRows = originalCustomFields.dataCaptureRows || [];
    if (originalRows.length > dataCaptureRows.length) return false;

    originalCustomFields.dataCaptureRows = dataCaptureRows
      .map((row, rowIndex) => {
        const originalRow = get(originalCustomFields, ['dataCaptureRows', rowIndex], {});
        if (!row.lastUpdated) return originalRow;

        const rowWithNaN = preserveNaNInUpdatedRow(originalRow, row);

        const rowOnDeviceLastUpdated = _.get(
          this.getWidget(id), `custom_fields.dataCaptureRows[${rowIndex}].lastUpdated`);
        if (!rowOnDeviceLastUpdated) return rowWithNaN;

        if (moment(row.lastUpdated).isAfter(moment(rowOnDeviceLastUpdated))) return rowWithNaN;

        return originalRow;
      });

    return true;
  },

  batchUpdate(widgets) {
    if (widgets != null) {
      widgets.forEach((w) => {
        if (this._widget_data[w.id] !== null) {
          _.merge(this._widget_data[w.id], w);
        }
      });
    }
  },

  saveCompleted(id) {
    this.getWidget(id).saving = false;
    this.getWidget(id).dirty = false;
    this.emitUpdateForWidgetAutosaveStatus(id);
  },

  submitted(id) {
    this.getWidget(id).submitted_at = new TrailStore().getServerTime().toDate();
    this.getWidget(id).dirty = false;
  },

  unsubmitted(id) {
    this.getWidget(id).submitted_at = null;
  },

  getJSONBody(id, withScores) {
    const {
      public_settings: { columns } = {},
      custom_fields: customFields,
    } = WidgetDataStore.getWidget(id);

    const dataCaptureScores = withScores ? DataCaptureScoreSelectors.dataCaptureScoresSelector(
      null,
      { rows: customFields.dataCaptureRows, columns }
    ) : null;
    const visibleFieldsByRow = this.visibleFieldsByRow(id);

    const body = {
      custom_fields: { ...customFields, dataCaptureScores },
      ...visibleFieldsByRow && { visible_fields_by_row: visibleFieldsByRow },
    };
    return JSON.stringify(body);
  },

  visibleFieldsByRow(id) {
    const {
      public_settings: { columns } = {},
      custom_fields: customFields,
      type,
    } = WidgetDataStore.getWidget(id);

    if (!DATA_TYPE_REGEX.dataCapture.test(type)) return null;

    return customFields.dataCaptureRows.map(
      row => calculateVisibleFieldsByLogic(row, columns)
    );
  },

  getCustomFields(id) {
    return this.getWidget(id).custom_fields;
  },

  getCustomField(id, key) {
    if (this._widget_data[id] === undefined) {
      return undefined;
    }
    if (this._widget_data[id].custom_fields &&
       this._widget_data[id].custom_fields[key] === undefined) {
      return '';
    }
    return this._widget_data[id].custom_fields[key];
  },

  setCustomField(id, key, value) {
    this.getWidget(id).dirty = true;
    if (this.getWidget(id).custom_fields) {
      this.getWidget(id).custom_fields[key] = value;
    }

    this.emitUpdateForWidget(id);
  },

  clearRowFields(id, rowIndex, fieldNames) {
    this.getWidget(id).dirty = true;
    if (this.getWidget(id).custom_fields) {
      const row = this.getWidget(id).custom_fields.dataCaptureRows[rowIndex];
      const preservedRowFieldNames = _.without(Object.keys(row), ...fieldNames);
      const updatedRow = _.pick(row, preservedRowFieldNames);
      updatedRow.lastUpdated = new TrailStore().getServerTime().valueOf();

      this.getWidget(id).custom_fields.dataCaptureRows[rowIndex] = updatedRow;
    }

    this.emitUpdateForWidget(id);
  },

  setTimer(id, rowId, data) {
    this.getWidget(id).dirty = true;
    if (this.getWidget(id).custom_fields) {
      const { prototypeTimerSettings } = this.getWidget(id).public_settings;
      this.getWidget(id).custom_fields.prototypeTimers =
        this.getWidget(id).custom_fields.prototypeTimers || [];
      const { prototypeTimers } = this.getWidget(id).custom_fields;
      const { hours, minutes } = prototypeTimerSettings;
      const timerIndex = data.index;
      if (timerIndex >= 0) {
        const reminder = prototypeTimerSettings.reminders[timerIndex];

        const foundIndex = prototypeTimers
          .findIndex(({
            rowIndex,
            index: reminderIndex,
          }) => rowIndex === rowId && timerIndex === reminderIndex);

        this.getWidget(id).custom_fields.prototypeTimers[foundIndex] = {
          ...data,
          widgetId: id,
          rowIndex: rowId,
          reminderHours: reminder.hours,
          reminderMinutes: reminder.minutes,
          hours,
          minutes,
          index: timerIndex,
        };
      } else {
        this.getWidget(id).custom_fields.prototypeTimers = prototypeTimerSettings
          .reminders
          .forEach(({
            hours: reminderHours,
            minutes: reminderMinutes,
          }, index) => {
            const foundIndex = prototypeTimers
              .findIndex(({
                rowIndex,
                index: reminderIndex,
              }) => rowIndex === rowId && index === reminderIndex);
            if (foundIndex === -1) {
              prototypeTimers.push({
                ...data,
                widgetId: id,
                rowIndex: rowId,
                reminderHours,
                reminderMinutes,
                hours,
                minutes,
                index,
              });
            } else {
              prototypeTimers[foundIndex] = {
                ...data,
                widgetId: id,
                rowIndex: rowId,
                reminderHours,
                reminderMinutes,
                hours,
                minutes,
                index,
              };
            }
          });
        this.getWidget(id).custom_fields.prototypeTimers = prototypeTimers;
      }
    }
    this.emitUpdateForWidget(id);
  },

  getCustomFieldRow(id, rowKey, rowId) {
    return _.get(this.getWidget(id).custom_fields, [rowKey, rowId]) || null;
  },

  setCustomFieldRow(id, rowKey, rowId, data) {
    data.lastUpdated = new TrailStore().getServerTime().valueOf();
    this.getWidget(id).dirty = true;
    if (!this.fieldRowDefined(id, rowKey)) {
      this.getWidget(id).custom_fields[rowKey] = [];
    }
    this.getWidget(id).custom_fields[rowKey][rowId] = data;

    this.emit(WidgetDataConstants.ON_CUSTOM_FIELD_ROW_SET, {
      widgetDataId: id,
      rowKey,
      rowId,
      data,
    });

    this.emitUpdateForWidget(id);
  },

  deleteCustomFieldRow(id, rowKey, rowId) {
    const existingData = _.get(this.getWidget(id), ['custom_fields', rowKey, rowId]);
    const customFieldRowKey = (this.getWidget(id).custom_fields &&
      this.getWidget(id).custom_fields[rowKey]) || {};
    this.getWidget(id).dirty = true;
    customFieldRowKey[rowId] = {
      ...existingData,
      rowDeleted: true,
      lastUpdated: new TrailStore().getServerTime().valueOf(),
    };

    this.emit(WidgetDataConstants.ON_CUSTOM_ROW_DELETED, {
      widgetDataId: id,
      rowKey,
      rowId,
    });

    this.emitUpdateForWidget(id);
  },

  fieldRowDefined(id, rowKey) {
    const customFields = this.getWidget(id).custom_fields;
    return Boolean(_.get(customFields, rowKey));
  },

  queueAutosave(id) {
    this.getWidget(id).saving = true;
    this.emitUpdateForWidgetAutosaveStatus(id);
  },

  emitUpdateForWidget(id) {
    this.emit(WidgetDataConstants.WIDGET_UPDATED, id, _.clone(this.getWidget(id)));
  },

  emitUpdateForWidgetAutosaveStatus(id) {
    const { saving } = this.getWidget(id);
    this.emit(WidgetDataConstants.WIDGET_AUTOSAVE_UPDATED, id, saving);
  },

  getNextTimer() {
    const trailStore = new TrailStore();
    const locationClosed = trailStore.getLocationClosed();
    const isToday = trailStore.isToday();

    const invalidTimer = locationClosed || !isToday;
    if (invalidTimer) return null;

    return Object.values(this.getAllWidgetData())
      .filter((widget) => {
        const customFields = widget.custom_fields;
        const task = trailStore.getTaskByWidgetId(widget.id);
        const invalidTimerTaskStatus = [
          TrailConstants.taskStatus.DONE,
          TrailConstants.taskStatus.FLAGGED,
          TrailConstants.taskStatus.SNOOZED,
        ];
        const taskCanTimeout = !invalidTimerTaskStatus.includes(task.status);
        return customFields && customFields.prototypeTimers && taskCanTimeout;
      })
      .map(widget => widget.custom_fields.prototypeTimers.map((timer) => {
        const endTime = new Date(timer.startTime).getTime() +
          Number(timer.hours) * 60 * 60 * 1000 +
          Number(timer.minutes) * 60 * 1000;
        return {
          ...timer,
          widgetId: widget.id,
          endTime,
          reminderTime: endTime -
            Number(timer.reminderHours) * 60 * 60 * 1000 -
            Number(timer.reminderMinutes) * 60 * 1000,
        };
      }))
      .flat()
      .filter(({
        widgetId, rowIndex, startTime, seen,
      }) => {
        const widget = this.getWidget(widgetId);
        const { dataCaptureRows } = widget.custom_fields;
        if (dataCaptureRows && dataCaptureRows[rowIndex]) {
          const { rowDeleted } = dataCaptureRows[rowIndex];
          return !rowDeleted && !seen && startTime;
        }
        return false;
      })
      .sort(({ reminderTime: left }, { reminderTime: right }) => {
        if (left < right) return -1;
        if (left > right) return 1;
        return 0;
      })[0] || null;
  },
});

WidgetDataStore.dispatchToken = AppDispatcher.register((payload) => {
  const action = payload.actionType;

  switch (action) {
    case WidgetDataConstants.QUEUE_AUTOSAVE:
      WidgetDataStore.queueAutosave(payload.id);
      break;

    case WidgetDataConstants.SAVE_SUCCESS:
      WidgetDataStore.saveCompleted(payload.id);
      break;

    case WidgetDataConstants.STORE_REBUILT:
      break;

    case WidgetDataConstants.WIDGET_UPDATED_BY_PUSHER:
      WidgetDataStore.updateWidgetByPusher(payload.id, payload.customFields);
      break;

    case WidgetDataConstants.REQUEST_CUSTOM_ROW_DELETE:
      WidgetDataStore.deleteCustomFieldRow(payload.id, payload.rowKey, payload.rowIndex);
      break;

    case WidgetDataConstants.CUSTOM_FIELD_ROW_CHANGED:
      WidgetDataStore.setCustomFieldRow(payload.id, payload.rowKey, payload.rowIndex, payload.row);
      break;

    case WidgetDataConstants.CUSTOM_FIELD_CHANGED:
      WidgetDataStore.setCustomField(payload.id, payload.key, payload.value);
      break;

    case WidgetDataConstants.ROW_FIELDS_CLEARED:
      WidgetDataStore.clearRowFields(payload.id, payload.rowIndex, payload.fieldNames);
      break;

    case WidgetDataConstants.TIMER_CHANGED:
      WidgetDataStore.setTimer(payload.id, payload.rowId, payload.data);
      break;

    case WidgetDataConstants.SUBMITTED:
      WidgetDataStore.submitted(payload.id);
      break;

    case WidgetDataConstants.UNSUBMITTED:
      WidgetDataStore.unsubmitted(payload.id);
      break;

    case WidgetDataConstants.SET_STORE:
      WidgetDataStore.setWidgetData(payload.widget_data);
      break;

    case TrailConstants.UPDATE_TRAIL:
      WidgetDataStore.setWidgetData(payload.jsonData.widget_data);
      break;

    case TrailConstants.ADD_CREATED_TIMELINE_TASK:
      if (payload.widget_data) {
        WidgetDataStore.setWidgetData([payload.widget_data]);
      }
      break;
    case WidgetDataConstants.ACTION_WIDGET_ADDED:
      WidgetDataStore.setWidgetData([payload.actionWidget]);
      break;
    default:
      break;
  }
});

WidgetDataStore.setWidgetData(window.prefetch.widget_data);

if (process.env.TEST === true) {
  window.WidgetDataStore = WidgetDataStore;
}

export { WidgetDataStore };
