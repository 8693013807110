import React from 'react';
import PropTypes from 'prop-types';
import { range, groupBy } from 'lodash';
import { useIntl } from 'react-intl';

import {
  DAY_MINUTES, LAST_DAY_OF_WEEK_NUM,
} from './constants';

const MINUTE_STEP = 15;

const TimeSelect = ({
  label, id, value, onChange, max, min, dayOfWeekNum,
}) => {
  const timeOptions = range(min, max, MINUTE_STEP);
  const timeOptionsByDayOffset = groupBy(timeOptions, time => Math.floor(time / DAY_MINUTES));
  const { formatMessage, formatDate, formatTime } = useIntl();

  return (
    <label htmlFor={ id } className='business-hours__select'>
      <div className='business-hours__select-label'>{label}</div>
      <select
        value={ value }
        id={ id }
        onChange={ onChange }
        data-test='time.select'
      >
        <option value=''>{ formatMessage({ id: 'timeslots.editor.timeSelect' }) }</option>
        {Object.entries(timeOptionsByDayOffset).map(([dayOffset, dayTimeOptions]) => {
          dayOffset = parseInt(dayOffset, 10);
          const dayNum = (dayOfWeekNum + dayOffset) % LAST_DAY_OF_WEEK_NUM;
          const dayDate = new Date();
          dayDate.setDate(dayDate.getDate() - dayDate.getDay() + dayNum);

          return (
            <optgroup
              label={ formatDate(dayDate, { weekday: 'long' }) }
              key={ dayNum }
            >
              {dayTimeOptions.map((time) => {
                const timeDate = new Date(1970, 0, 1, 0, time);
                return (
                  <option key={ time } value={ time }>
                    { formatTime(timeDate, { hour: 'numeric', minute: 'numeric' }) }
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </select>
    </label>
  );
};

TimeSelect.propTypes = {
  label: PropTypes.string.isRequired,
  dayOfWeekNum: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  id: PropTypes.string,
};

TimeSelect.defaultProps = {
  value: 0,
  id: '',
  max: DAY_MINUTES * 2,
  min: 0,
  dayOfWeekNum: 0,
};

export default TimeSelect;
