function isArrayWithLength(obj) {
  return obj instanceof Array && obj.length > 0;
}

export const timeSlotHelper = {
  allAreClosed(timeSlots) {
    if (!isArrayWithLength(timeSlots)) return false;
    return timeSlots.every(timeSlot => timeSlot.isClosed);
  },
  getTimeslotsForDay(timeSlots, dayOfWeek) {
    if (!isArrayWithLength(timeSlots)) return [];
    return timeSlots.filter(timeSlot => timeSlot.dayOfWeekNum === dayOfWeek);
  },
  getClosedDaysFromTimeslots(timeSlots) {
    const closedWeekDays = [];
    let dayOfWeek = 0;
    while (dayOfWeek < 7) {
      const timeSlotsInDayOfWeek = this.getTimeslotsForDay(timeSlots, dayOfWeek);
      if (this.allAreClosed(timeSlotsInDayOfWeek)) {
        closedWeekDays.push(dayOfWeek);
      }
      dayOfWeek += 1;
    }
    return closedWeekDays;
  },
};

export const curlyBrackets = {
  wrap(string) {
    return `{${string}}`;
  },
  unwrap(string) {
    return string.replace(/^{|}$/g, '');
  },
};

export const dateHelper = {
  railsToJs(str) {
    const d = new Date(`${str}T00:00:00.000Z`);
    return d;
  },
  jsToRails(date) {
    function pad(n) {
      return n < 10 ? `0${n}` : n;
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${pad(month)}-${pad(day)}`;
  },
};

export const railsToJsDateArrayMapper = {
  mapToJs(railsDateArray) {
    return curlyBrackets.unwrap(railsDateArray).split(',').filter(x => x).map(dateHelper.railsToJs);
  },
  mapToRails(jsDateArray) {
    const dateSorter = (date1, date2) => date1 - date2;
    return curlyBrackets.wrap(jsDateArray.sort(dateSorter).map(dateHelper.jsToRails).join(','));
  },
};
