import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TimeslotContainer = ({ height, children }) => (
  <div
    className={ classNames('business-hours__timeslot_container', {
      'business-hours__timeslot_container--error': height === 0,
      'business-hours__timeslot_container--thin': height && height < 45,
    }) }
    data-test='timeslot'
    style={ { height } }
  >
    {children}
  </div>
);

TimeslotContainer.propTypes = {
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
};

TimeslotContainer.defaultProps = {
  children: null,
};

const Timeslot = ({
  height, label, isClosed, onClick, focused,
}) => {
  if (isClosed) return <TimeslotContainer height={ height } />;

  return (
    <TimeslotContainer height={ height }>
      <a
        className={
          classNames('business-hours__timeslot', {
            'business-hours__timeslot--focused': focused,
          })
        }
        onClick={ onClick }
      >
        {label}
      </a>
    </TimeslotContainer>
  );
};

Timeslot.propTypes = {
  label: PropTypes.string.isRequired,
  isClosed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

const TimeslotBlocks = ({ timeslots, onTimeslotClick, focusedTimeslot }) => (
  <div className='business-hours__timeslots' data-test='timeslots' style={ { marginTop: timeslots[0].minute } }>
    {timeslots.map(timeslot => (
      <Timeslot
        key={ timeslot.baseTimeslotUuid }
        focused={ focusedTimeslot === timeslot.baseTimeslotUuid }
        label={ timeslot.name }
        height={ timeslot.duration }
        isClosed={ timeslot.isClosed }
        onClick={ () => onTimeslotClick(timeslot.baseTimeslotUuid) }
      />
    ))}
  </div>
);

TimeslotBlocks.propTypes = {
  timeslots: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number,
    name: PropTypes.string,
    minute: PropTypes.number,
    baseTimeslotUuid: PropTypes.string,
  })).isRequired,
  onTimeslotClick: PropTypes.func.isRequired,
  focusedTimeslot: PropTypes.string,
};

TimeslotBlocks.defaultProps = {
  focusedTimeslot: null,
};

export default TimeslotBlocks;
