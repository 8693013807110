import CodeMirror from 'codemirror';
// This is the only known way of making the Rails unobtrusive javascript work.
// Details: https://stackoverflow.com/a/39427086
import $ from 'jquery';

export { CodeMirrorConfig } from './CodeMirrorConfig';
export { timeslotStoreShim } from './timeslotStoreShim';
export { dateHelper } from './locationClosedDaysProps';
export { StatusLegacy } from './StatusLegacy';
if (process.env.TEST === true) {
  global.CodeMirror = CodeMirror;
}
global.jQuery = $;
require('jquery-ujs');
