import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { isTimeslotMatch } from './utils';
import Button from '../../common/Button';

const CloseTimeslot = ({ timeslot: timeslotToClose, timeslots, onTimeslotClose }) => {
  const { formatMessage } = useIntl();

  const onClick = () => {
    const updatedTimeslots = timeslots.map(timeslot => (
      isTimeslotMatch(timeslotToClose, timeslot)
        ? { ...timeslot, isClosed: true } : timeslot
    ));
    onTimeslotClose(updatedTimeslots);
  };

  return (
    <div className='business-hours__editor-timeslot-close'>
      <div className='showtime-utility-font-body-small-bold'>
        { formatMessage({ id: 'timeslots.editor.close.or' }) }
      </div>
      <div className='showtime-utility-font-headline-fluid-bold'>
        { formatMessage({ id: 'timeslots.editor.close.title' }) }
      </div>
      <div className='showtime-utility-font-body-small'>
        { formatMessage({ id: 'timeslots.editor.close.description' }) }
      </div>
      <Button
        onClick={ onClick }
        className='showtime-button--delete-confirm'
        dataTest='timeslot.close'
      >
        { formatMessage({ id: 'timeslots.editor.close.button' }) }
      </Button>
    </div>
  );
};

const timeslotPropType = PropTypes.shape({
  duration: PropTypes.number,
  name: PropTypes.string,
  hour: PropTypes.number,
  minute: PropTypes.number,
  isClosed: PropTypes.bool,
  dayOffset: PropTypes.number,
  baseTimeslotUuid: PropTypes.string,
});

CloseTimeslot.propTypes = {
  timeslot: timeslotPropType.isRequired,
  timeslots: PropTypes.arrayOf(timeslotPropType).isRequired,
  onTimeslotClose: PropTypes.func.isRequired,
};

export default CloseTimeslot;
