import {
  get, find, range, uniq,
} from 'lodash';
import { createSelector } from 'reselect';
import { isLoaded } from '../../common/requestStatuses';
import { DAYS_IN_WEEK_NUM } from '../TimeslotsEditor/constants';

export const organizationTimeslotsLoadedSelector = state => isLoaded(get(state, 'timeslots.organization.requestStatus'));

const organizationTimeslotEntitiesSelector = state => get(state, 'timeslots.organization.entities', {});

export const initialOrgTimeslotsSelector = state => (
  organizationTimeslotEntitiesSelector(state).initialTimeslots);

const organizationTimeslotOverridesSelector = state => get(state, 'timeslots.organization.entities.timeslotOverrides', []);

export const organizationDaysWithOverridesSelector = createSelector(
  organizationTimeslotOverridesSelector,
  overrides => uniq(overrides.map(({ dayOfWeekNum }) => dayOfWeekNum))
);

export const organizationTimeslotDefaultDaysSelector = createSelector(
  organizationTimeslotEntitiesSelector,
  organizationTimeslotsLoadedSelector,
  ({ defaultTimeslots }, loaded) => {
    if (!loaded) return [];

    return range(0, DAYS_IN_WEEK_NUM).map(dayOfWeekNum => (
      defaultTimeslots.map(defaultTimeslot => ({
        ...defaultTimeslot,
        type: null,
        dayOfWeekNum,
      }))
    )).flat();
  }
);

export const organizationTimeslotDaysSelector = createSelector(
  organizationTimeslotDefaultDaysSelector,
  organizationTimeslotOverridesSelector,
  organizationTimeslotsLoadedSelector,
  (defaultTimeslotDays, timeslotOverrides, loaded) => {
    if (!loaded) return [];

    return defaultTimeslotDays.map((defaultTimeslot) => {
      const override = find(timeslotOverrides, {
        dayOfWeekNum: defaultTimeslot.dayOfWeekNum,
        baseTimeslotUuid: defaultTimeslot.baseTimeslotUuid,
      });
      return {
        ...defaultTimeslot,
        ...override || {},
      };
    });
  }
);

export const organizationDefaultTimeslotsSelector = state => (
  organizationTimeslotEntitiesSelector(state).defaultTimeslots);
