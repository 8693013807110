import _ from 'lodash';
import moment from 'moment-timezone';
import { getCurrentUserRole } from '../application/UserRole';

import queryBuilder, { buildQueryString } from '../common/api/queryBuilder';
import { TaskTemplateConstants } from '../task/constants';

const { ACTION, ACTION_TEMPLATE } = TaskTemplateConstants.templateType;

export const CLEAR_TASKS = '@TASK_REPORTS clear_tasks';
export const FETCH_COUNT_TASK_REPORTS_KEY = '@TASK_REPORTS fetch_count_task_reports_key';
export const FETCH_TASK_REPORTS_KEY = '@TASK_REPORTS fetch_task_reports_key';
export const FETCH_TASK_REPORT_CONTENTS_KEY = '@TASK_REPORTS fetch_task_report_contents_key';
export const FETCH_TASK_SUBTASKS_KEY = '@TASK_REPORTS fetch_task_subtasks_key';
export const FETCH_TASK_TEMPLATES_KEY = '@TASK_REPORTS fetch_task_templates_key';
export const TOGGLE_VIEW_FILTERS = '@TASK_REPORTS toggle_view_filters';
export const FETCH_TASK_REPORTS_REQUEST_BATCH_COMPLETE = '@TASK_REPORTS request_batch_complete';
export const SET_EXPORT_MODE = '@TASK_REPORTS set_export_mode';
export const SET_SELECTED_TASK_IDS = '@TASK_REPORTS set_selected_task_ids';

export const COUNT_TASK_REPORTS_URL = '/api/task_reports/count';
export const TASK_REPORTS_INDEX_URL = '/api/task_reports';
export const TASK_REPORTS_TASK_TEMPLATES_URL = '/api/task_reports/task_templates';
export const FILE_PATH = '/file/:handle';
export const TASK_REPORTS_EXPORT_CSV_URL = '/api/task_reports/export';
export const TASK_REPORTS_EXPORT_CSV_KEY = 'task_reports_export';
export const TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_URL = '/api/task_reports/export/exceptions';
export const TASK_REPORTS_EXPORT_EXCEPTIONS_CSV_KEY = 'task_reports_exceptions_export';

export const fetchTaskSubtasksUrl = taskIds => queryBuilder(
  '/api/task_reports/task_subtasks', { 'task_ids[]': taskIds });

export const filterTypes = {
  SITES: 'SITES',
  TAGS: 'TAGS',
  STATUSES: 'STATUSES',
  TASKS: 'TASKS',
  DATES: 'DATES',
  EXCEPTIONS: 'EXCEPTIONS',
  ACTIONS: 'ACTIONS',
  ROLES: 'ROLES',
  USERS: 'USERS',
};

export const HIDDEN_FILTERS_FOR_ACTIONS = [
  filterTypes.TASKS,
  filterTypes.EXCEPTIONS,
];

export const filterQueryKeys = {
  SEARCH: 'search',
  SITES: 'sites',
  TAGS: 'tag',
  STATUSES: 'status',
  TASKS: 'task',
  DATES: 'dates',
  EXCEPTIONS: 'exceptions',
  ACTIONS: 'actions',
  VIEW_COMMENTS: 'viewComments',
  VIEW_CHECKLISTS: 'viewChecklists',
  VIEW_RECORD_LOGS: 'viewRecordLogs',
  ROLES: 'roles',
  USERS: 'users',
};

export const exceptionTypes = {
  taskComment: 'comment',
  widget: 'widget',
  flaggedSubtask: 'flagged_subtask',
  newSubtask: 'new_subtask',
  reopenedTask: 'reopened',
  hasAction: 'action',
  subtaskComment: 'subtask_comment',
  widgetComment: 'widget_comment',
};

export const exceptionOptions = [
  exceptionTypes.taskComment,
  exceptionTypes.widget,
  exceptionTypes.flaggedSubtask,
  exceptionTypes.newSubtask,
  exceptionTypes.reopenedTask,
  exceptionTypes.hasAction,
  exceptionTypes.subtaskComment,
  exceptionTypes.widgetComment,
];

export const TASK_CONTENT_COMMENT_EXCEPTION_TYPES = [
  exceptionTypes.subtaskComment, exceptionTypes.widgetComment];

export const getAvailableExceptionOptions = () => {
  const flagOverrides = {
    action: !!window.config.features.correctiveActions,
    subtask_comment: !!window.config.features.inlineComments,
    widget_comment: !!window.config.features.inlineComments,
  };

  return exceptionOptions.filter(exception => (
    _.isUndefined(flagOverrides[exception]) || flagOverrides[exception]
  ));
};

export const datesMetricValues = {
  LAST90: 'last90',
  LAST30: 'last30',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  CUSTOM: 'custom',
};

export const filterTypeIcons = {
  [filterTypes.SITES]: 'mini/shopping/shopping-16px_shop',
  [filterTypes.TAGS]: 'mini/shopping/shopping-16px_tag',
  [filterTypes.STATUSES]: 'mini/design-development/design-16px_todo',
  [filterTypes.TASKS]: 'mini/business-finance/business-16px_notes',
  [filterTypes.DATES]: 'mini/ui/ui-16px-1_calendar-60',
  [filterTypes.EXCEPTIONS]: 'mini/ui/ui-16px-3_alert',
  [filterTypes.ACTIONS]: 'mini/arrows/arrows-16px-2_corner-right-round',
  [filterTypes.ROLES]: 'mini/users/users-16px_multiple-19',
  [filterTypes.USERS]: 'mini/users/users-16px_single_4',
};

export const filterLabelPaths = Object.values(filterTypes).reduce((accu, filter) => ({
  ...accu,
  [filter]: `taskReports.filters.${filter.toLowerCase()}`,
}), {});

export const isFilterEnabled = (value) => {
  const {
    correctiveActions, tags, exceptionReporting, managerTasks, assignTasksToUsers,
  } = window.config.features;
  const { userAssignedToSingleSite, locationId: viewingSingleLocation } = window.navigation;
  const CurrentUserRole = getCurrentUserRole();
  return ({
    ..._.mapValues(filterTypes, () => true),
    [filterTypes.ACTIONS]: correctiveActions,
    [filterTypes.SITES]: !userAssignedToSingleSite && !viewingSingleLocation,
    [filterTypes.TAGS]: tags,
    [filterTypes.EXCEPTIONS]: exceptionReporting,
    [filterTypes.ROLES]: managerTasks && CurrentUserRole.isManagerOrAbove(),
    [filterTypes.USERS]: assignTasksToUsers && CurrentUserRole.isManagerOrAbove(),
  }[value]);
};

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';
export const DATE_QUERY_SEPARATOR = '..';

export const getDefaultFilter = () => {
  const todayInIso = moment().format('YYYY-MM-DD');
  return buildQueryString(
    { [filterQueryKeys.DATES]: `${todayInIso}${DATE_QUERY_SEPARATOR}${todayInIso}` }
  );
};

export const ALL = 'all';

export const filterQueryKeysToTypes = _.invert(filterQueryKeys);

const COMPLETED_EARLY_STATUS = 'early';
const COMPLETED_LATE_STATUS = 'late';
const COMPLETED_ON_TIME_STATUS = 'ontime';
const IN_PROGRESS_STATUS = 'inprogress';
const OVERDUE_STATUS = 'overdue';
const MISSED_STATUS = 'missed';
const FLAGGED_STATUS_NOT_DONE = 'not_done';
const FLAGGED_STATUS_NOT_APPLICABLE = 'not_applicable';
const FLAGGED_STATUS_NEVER_APPLICABLE = 'never_applicable';
export const FLAGGED_STATUS_UNFLAGGED = 'unflagged';
export const FLAGGED_STATUS = 'flagged';

export const queryStatuses = [
  IN_PROGRESS_STATUS,
  OVERDUE_STATUS,
  MISSED_STATUS,
  COMPLETED_EARLY_STATUS,
  COMPLETED_ON_TIME_STATUS,
  COMPLETED_LATE_STATUS,
  FLAGGED_STATUS_NOT_DONE,
  FLAGGED_STATUS_NOT_APPLICABLE,
  FLAGGED_STATUS_NEVER_APPLICABLE,
];

export const taskStatusToQueryStatus = {
  completed_early: COMPLETED_EARLY_STATUS,
  completed_late: COMPLETED_LATE_STATUS,
  completed: COMPLETED_ON_TIME_STATUS,
  in_progress: IN_PROGRESS_STATUS,
  overdue: OVERDUE_STATUS,
  missed: MISSED_STATUS,
  not_done: FLAGGED_STATUS_NOT_DONE,
  not_applicable: FLAGGED_STATUS_NOT_APPLICABLE,
  never_applicable: FLAGGED_STATUS_NEVER_APPLICABLE,
};

export const flaggingTasksFeatureFlaggedStatuses = [
  FLAGGED_STATUS_UNFLAGGED,
  FLAGGED_STATUS_NOT_DONE,
  FLAGGED_STATUS_NOT_APPLICABLE,
  FLAGGED_STATUS_NEVER_APPLICABLE,
];

const buildStatusQuery = status => buildQueryString({ [filterQueryKeys.STATUSES]: status });

export const getFixedShortcuts = () => {
  const shortcuts = [
    { labelPath: 'all', queryString: '' },
    { labelPath: 'missed', queryString: buildStatusQuery(MISSED_STATUS) },
    { labelPath: 'completedLate', queryString: buildStatusQuery(COMPLETED_LATE_STATUS) },
    { labelPath: 'completedEarly', queryString: buildStatusQuery(COMPLETED_EARLY_STATUS) },
  ];

  if (window.config.features.exceptionReporting) {
    shortcuts.push({
      labelPath: 'widgetExceptions',
      queryString: buildQueryString({ [filterQueryKeys.EXCEPTIONS]: exceptionTypes.widget }),
    },
    {
      labelPath: 'comments',
      queryString: buildQueryString({
        [filterQueryKeys.VIEW_COMMENTS]: true,
        [filterQueryKeys.EXCEPTIONS]: [exceptionTypes.taskComment],
      }),
    });
  }

  if (window.config.features.correctiveActions) {
    shortcuts.push({
      labelPath: 'onlyActions',
      queryString: buildQueryString({
        [filterQueryKeys.ACTIONS]: [ACTION, ACTION_TEMPLATE].join(','),
      }),
    });
  }
  return shortcuts;
};

export const taskOnTrailUrl = timelineTaskId => `/taskontrail?task=${timelineTaskId}`;
export const fetchTaskReportContentsUrl = ids => queryBuilder(
  '/api/task_reports/task_content', {
    'ids[]': ids,
  });

export const PAGINATION_LIMIT = 30;

export const FORCE_VIEW_FILTERS_WIDTH = 768;

export const TASK_PDF_EXPORT_LIMIT = 10;

export const ATTRIBUTE_TYPES = {
  LOCATION: 'location',
  FLAGS: 'flags',
  CHECKS_ADDED: 'checksAdded',
  COMMENTS: 'comments',
  ACTIONS: 'actions',
  EXCEPTIONS: 'exceptions',
  LINKED_TIMELINE_TASK: 'linkedTimelineTask',
};

export const TASK_ROUTE_PREFIX = '/tasks';
export const PREVIEW_TASKS_PDF = '/tasks/preview_pdf';
