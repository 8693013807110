import _ from 'lodash';
import { TaskPlannerConstants } from '../constants';
import { RequestConstants, RequestActions } from '../../request';
import { TaskWizardActions } from '../../task-wizard/actions';
import FlashActions from '../../flash/actions';

export const TaskPlannerActions = {
  getTaskPlan: organizationId => dispatch => dispatch(RequestActions.request({
    url: TaskPlannerConstants.buildGetTaskPlannerUrl(organizationId),
    key: TaskPlannerConstants.GET_TASK_PLANNER_KEY,
    method: RequestConstants.GET,
    errorMessage: 'taskplanner.error_message',
  })).then(({ success }) => {
    const taskTemplateIds = _.chain(success)
      .get('body.task_templates', [])
      .map('id').value();

    if (taskTemplateIds.length) {
      dispatch(TaskPlannerActions.getTaskPreviews(taskTemplateIds));
    }

    return Promise.resolve();
  }),

  highlightTaskTemplate: taskTemplateId => ({
    type: TaskPlannerConstants.HIGHLIGHT_TASK_TEMPLATE,
    taskTemplateId,
  }),

  getSingleLocationTaskPlan(locationId) {
    return RequestActions.request({
      url: TaskPlannerConstants.buildSingleLocationTaskplannerUrl(locationId),
      key: TaskPlannerConstants.GET_TASK_PLANNER_KEY,
      method: RequestConstants.GET,
      errorMessage: 'taskplanner.error_message',
    });
  },
  getTaskPreviews(taskTemplateIds) {
    const body = { task_template_ids: taskTemplateIds };
    return RequestActions.request({
      url: TaskPlannerConstants.getTaskPreviewsUrl(),
      key: TaskPlannerConstants.GET_TASK_PREVIEWS_KEY,
      method: RequestConstants.PUT,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmAssign(taskIds, trailIds) {
    const body = {
      task_ids: taskIds,
      trail_ids: trailIds,
    };

    return RequestActions.request({
      url: TaskPlannerConstants.ASSIGN_TO_TRAIL_URL,
      key: TaskPlannerConstants.ASSIGN_TO_TRAIL,
      method: RequestConstants.PUT,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmAddTagsToTasks(taskIds, tagIds) {
    const body = {
      task_ids: taskIds,
      tag_ids: tagIds,
    };

    return RequestActions.request({
      url: TaskPlannerConstants.ADD_TAGS_TO_TASKS_URL,
      key: TaskPlannerConstants.ADD_TAGS_TO_TASKS,
      method: RequestConstants.PUT,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmUnassign(taskIds, trailIds) {
    const body = {
      task_ids: taskIds,
      trail_ids: trailIds,
    };

    return RequestActions.request({
      url: TaskPlannerConstants.UNASSIGN_FROM_TRAIL_URL,
      key: TaskPlannerConstants.UNASSIGN_FROM_TRAIL,
      method: RequestConstants.PUT,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmRemoveTagsFromTasks(taskIds, tagIds) {
    const body = {
      task_ids: taskIds,
      tag_ids: tagIds,
    };

    return RequestActions.request({
      url: TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS_URL,
      key: TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS,
      method: RequestConstants.PUT,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmDelete(id) {
    const body = { task_ids: id };
    return RequestActions.request({
      url: TaskPlannerConstants.DELETE_TASK_URL,
      key: TaskPlannerConstants.DELETE_TASK_KEY,
      method: RequestConstants.POST,
      errorMessage: 'taskplanner.error_message',
      options: {
        body: JSON.stringify(body),
      },
    });
  },

  confirmArchive: (ids, successMessage) => dispatch => dispatch(RequestActions.request({
    url: TaskPlannerConstants.ARCHIVE_TASK_URL,
    key: TaskPlannerConstants.ARCHIVE_TASK_KEY,
    method: RequestConstants.PUT,
    errorMessage: 'taskplanner.error_message',
    options: {
      body: JSON.stringify({ task_ids: ids }),
    },
  })).then(({ success }) => {
    if (success) {
      dispatch(TaskWizardActions.returnToPlanner());
      FlashActions.notice(successMessage);
    }

    return Promise.resolve();
  }),

  filterByName(text) {
    return {
      type: TaskPlannerConstants.FILTER_BY_NAME,
      text,
    };
  },

  clearFilterByName() {
    return {
      type: TaskPlannerConstants.CLEAR_FILTER,
      filterName: TaskPlannerConstants.FILTER_BY_NAME,
    };
  },

  filterByTrail(id) {
    return {
      type: TaskPlannerConstants.FILTER_BY_TRAIL,
      locationId: parseInt(id, 10),
    };
  },

  clearFilterByTrail() {
    return {
      type: TaskPlannerConstants.CLEAR_FILTER,
      filterName: TaskPlannerConstants.FILTER_BY_TRAIL,
    };
  },

  filterByGroup(id) {
    return {
      type: TaskPlannerConstants.FILTER_BY_GROUP,
      timelineTemplateId: parseInt(id, 10),
    };
  },

  clearFilterByGroup() {
    return {
      type: TaskPlannerConstants.CLEAR_FILTER,
      filterName: TaskPlannerConstants.FILTER_BY_GROUP,
    };
  },

  filterByTag(id) {
    return {
      type: TaskPlannerConstants.FILTER_BY_TAG,
      tagId: id,
    };
  },

  clearFilterByTag() {
    return {
      type: TaskPlannerConstants.CLEAR_FILTER,
      filterName: TaskPlannerConstants.FILTER_BY_TAG,
    };
  },

  filterByCreator(id) {
    return {
      type: TaskPlannerConstants.FILTER_BY_CREATOR,
      creatorId: parseInt(id, 10),
    };
  },

  cancelAssignment() {
    return {
      type: TaskPlannerConstants.CANCEL_ASSIGNMENT,
    };
  },

  selectAllTasks(tasks) {
    return {
      type: TaskPlannerConstants.SELECT_ALL_TASKS,
      tasks,
    };
  },

  clearAllTasks() {
    return {
      type: TaskPlannerConstants.CLEAR_ALL_TASKS,
    };
  },

  reset() {
    return {
      type: TaskPlannerConstants.RESET,
    };
  },
  removeAssignmentSuccessNotice() {
    return {
      type: TaskPlannerConstants.REMOVE_ASSIGNMENT_SUCCESS_NOTICE,
    };
  },

  removeAddTagsToTasksSuccessNotice() {
    return {
      type: TaskPlannerConstants.ADD_TAGS_TO_TASKS_SUCCESS_NOTICE,
    };
  },

  removeUnassignmentSuccessNotice() {
    return {
      type: TaskPlannerConstants.REMOVE_UNASSIGNMENT_SUCCESS_NOTICE,
    };
  },

  removeTagsFromTasksSuccessNotice() {
    return {
      type: TaskPlannerConstants.REMOVE_TAGS_FROM_TASKS_SUCCESS_NOTICE,
    };
  },

  removeDeleteSuccessNotice() {
    return {
      type: TaskPlannerConstants.REMOVE_DELETE_SUCCESS_NOTICE,
    };
  },

  expandAllTasks(allTasks) {
    return {
      type: TaskPlannerConstants.EXPAND_ALL_TASKS,
      allTasks,
    };
  },

  collapseAllTasks() {
    return {
      type: TaskPlannerConstants.COLLAPSE_ALL_TASKS,
    };
  },
};
