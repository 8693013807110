const SAVEABLE_TIMESLOT_FIELDS = [
  'dayOfWeek',
  'dayOfWeekNum',
  'endTime',
  'isClosed',
  'level',
  'locationId',
  'name',
  'position',
  'startTime',
  'uuid',
  'baseTimeslotUuid',
];

const TIMESLOT_ATTRIBUTES = [
  'dayOfWeekNum',
  'hour',
  'minute',
  'baseTimeslotUuid',
  'dayOffset',
  'isClosed',
];

export const TimeslotConstants = {
  TIME_FMT: 'HH:mm',
  DAYS: 'days',
  OPEN: 'time_slot.open',
  CLOSE: 'time_slot.close',
  SET_TIME: 'time_slot.set_time',
  DEFAULT_TIMESLOTS: 'defaultTimeslots',
  TIMESLOTS_LOADED: 'time_slot.loaded',
  FETCH_LOCATION_TIMESLOTS: 'time_slot.fetch_location_timeslots',
  FETCH_ORGANIZATION_TIMESLOTS: 'time_slot.fetch_organization_timeslots',
  UPDATE_LOCATION_TIMESLOTS: 'time_slot.update_location_timeslots',
  UPDATE_ORGANIZATION_TIMESLOTS: 'time_slot.update_organization_timeslots',
  UPDATE_DEFAULT_ORGANIZATION_TIMESLOTS: 'time_slot.update_default_organization_timeslots',
  UPDATE_ORG_TIMESLOT_OVERRIDES: 'time_slot.update_org_timeslot_overrides',
  ORGANIZATION_TIMESLOTS_URL(id) {
    return `/api/v1/organizations/${id}/timeslots`;
  },
  locationTimeslotsUrl: id => `/api/v1/locations/${id}/timeslots`,
  SAVEABLE_TIMESLOT_FIELDS,
  TIMESLOT_ATTRIBUTES,
};
